// extracted by mini-css-extract-plugin
export var carouselContainer = "F_vY d_w d_H d_bf d_Z";
export var carouselContainerCards = "F_vZ F_vY d_w d_H d_bf d_Z";
export var carouselContainerSides = "F_v0 d_w d_H d_Z";
export var prevCarouselItem = "F_v1 d_w d_H d_0 d_k";
export var prevCarouselItemL = "F_v2 F_v1 d_w d_H d_0 d_k";
export var moveInFromLeft = "F_v3";
export var prevCarouselItemR = "F_v4 F_v1 d_w d_H d_0 d_k";
export var moveInFromRight = "F_v5";
export var selectedCarouselItem = "F_v6 d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "F_v7 F_v6 d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "F_v8 F_v6 d_w d_H d_Z d_bf";
export var nextCarouselItem = "F_v9 d_w d_H d_0 d_k";
export var nextCarouselItemL = "F_wb F_v9 d_w d_H d_0 d_k";
export var nextCarouselItemR = "F_wc F_v9 d_w d_H d_0 d_k";
export var arrowContainer = "F_wd d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "F_wf F_wd d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "F_wg F_wf F_wd d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "F_wh F_wd d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "F_wj";
export var nextArrowContainerHidden = "F_wk F_wh F_wd d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "F_kG d_0";
export var prevArrow = "F_wl F_kG d_0";
export var nextArrow = "F_wm F_kG d_0";
export var carouselIndicatorContainer = "F_wn d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "F_wp d_w d_bz d_bF";
export var carouselText = "F_wq d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "F_wr F_wq d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "F_ws d_b7";
export var carouselIndicator = "F_wt F_ws d_b7";
export var carouselIndicatorSelected = "F_wv F_ws d_b7";
export var arrowsContainerTopRight = "F_ww d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "F_wx d_0 d_bl d_bC";
export var arrowsContainerSides = "F_wy d_0 d_bl d_bC";
export var smallArrowsBase = "F_wz d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "F_wB F_wz d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "F_wC F_wB F_wz d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "F_wD F_wz d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "F_wF F_wD F_wz d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "F_qr";
export var iconSmall = "F_wG";
export var multipleWrapper = "F_wH d_bC d_bF d_bf";
export var multipleImage = "F_wJ d_bC";
export var sidesPrevContainer = "F_wK F_wB F_wz d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "F_wL F_wB F_wz d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";