// extracted by mini-css-extract-plugin
export var lbContainerOuter = "C_vH";
export var lbContainerInner = "C_vJ";
export var movingForwards = "C_vK";
export var movingForwardsOther = "C_vL";
export var movingBackwards = "C_vM";
export var movingBackwardsOther = "C_vN";
export var lbImage = "C_vP";
export var lbOtherImage = "C_vQ";
export var prevButton = "C_vR";
export var nextButton = "C_vS";
export var closing = "C_vT";
export var appear = "C_vV";