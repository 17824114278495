// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_rN d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_rP d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_rQ d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_rR d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_rS d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_rT d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_rV d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_rW y_rW";
export var heroExceptionNormal = "t_rX y_rX";
export var heroExceptionLarge = "t_rY y_rY";
export var Title1Small = "t_rZ y_rZ y_sK y_sL";
export var Title1Normal = "t_r0 y_r0 y_sK y_sM";
export var Title1Large = "t_r1 y_r1 y_sK y_sN";
export var BodySmall = "t_r2 y_r2 y_sK y_s2";
export var BodyNormal = "t_r3 y_r3 y_sK y_s3";
export var BodyLarge = "t_r4 y_r4 y_sK y_s4";